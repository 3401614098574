body {
    margin-top: 2%;
    /* background-color: #dfdada; */
}

/* .row {
    margin-left: 300px;
    margin-right: 300px;
} */

.heading {
    display: flex !important;
    text-align: left !important;
    font-weight: 600;
    margin-bottom: 0 !important;
}

h1 {
    font-size: 2rem;
    color: #712083;
    text-align: center !important;
    font-weight: 600;
}

.mx-auto {
    margin-top: 30px;
}

label {
    font-size: 20px;
}

.left {
    text-align: left !important;
}

.btn-custom {
    padding: 7px 30px;
    font-size: 1.1rem;
}

.btn-sub {
    display: inline-flex;
    justify-content: center;
}

.btn-add {
    font-weight: 600;
    background-color: #712083;
    border-color: #712083;
}

.btn-add:hover {
    font-weight: 600;
    background-color: #7e7e7e;
    border-color: #712083;
}

.attributes {
    display: flex !important;
}

.attr-label {
    font-size: 18px;
    padding-top: 5px;
    padding-right: 5px;
}

hr {
    margin-right: 0px;
    margin-left: 13%;
    margin-top: 0px;
    width: 15.1rem;
    border-top: 2px solid white;
}

/*** Image upload *****/
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
}

svg {
    width: 80px;
}

.btn-file {
    border: 2px dashed gray;
    color: gray;
    background-color: white;
    padding: 50px 0px;
    width: 100%;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}