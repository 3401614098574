.ag-theme-balham .ag-ltr .ag-cell {
  border-right: 1px solid #d9dddf;
}

.grid-container-table {
  width: 100%;
  height: 32.2em;
}

@media (max-width: 1000px) {
  .grid-container-table {
    width: 100%;
  }
}

.svgStyle {
  width: 25px;
}

/* scrollbar.css */

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.excel_button_styles {
  padding: 5px 6px !important;
  font-size: 1em !important;
  background: #0d6efd !important;
  border-radius: 5px !important;
}
