.ag-theme-balham .ag-ltr .ag-cell {
  border-right: 1px solid #d9dddf;
}

.grid-container {
  width: 1252px;
  height: 70vh;
  overflow: auto;
}

@media (max-width: 1000px) {
  .grid-container {
    height: 70vh;
    width: 100%;
  }
}
